import { Box, ButtonBase, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { rgba } from "polished";
import { useEffect, useState, useMemo } from "react";
import useSWR from "swr";

import WidgetAgendaMedium from "@cosy/components/WidgetAgendaMedium";
import WidgetImageSmall from "@cosy/components/WidgetImageSmall";
import WidgetMediumList from "@cosy/components/WidgetMediumList";
import WidgetMenu from "@cosy/components/WidgetMenu";
import WidgetNumberSmall from "@cosy/components/WidgetNumberSmall";
import WidgetUpNextMedium from "@cosy/components/WidgetUpNextMedium";
import WidgetUpNextSmall from "@cosy/components/WidgetUpNextSmall";
import useCustomTheme, {
  CustomThemeProvider,
} from "@cosy/hooks/use_custom_theme";
import useWorkspace from "@cosy/hooks/use_workspace";
import fetcher from "@cosy/utils/fetcher";

const TEMPLATE_WIDGET_MAP = {
  numberSmall: WidgetNumberSmall,
  mediumList: WidgetMediumList,
  imageSmall: WidgetImageSmall,
  upNextMedium: WidgetUpNextMedium,
  agendaMedium: WidgetAgendaMedium,
  upNextSmall: WidgetUpNextSmall,
};

const _TWO_DAYS_MS = 1000 * 60 * 60 * 24 * 2;

const useStyles = makeStyles((theme) => ({
  container: {
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shorter,
    }),
    width: "100%",
    height: "100%",
    "&.isRemoving": {
      opacity: 0.5,
    },
  },
  widgetCard: {
    borderRadius: 12,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  widgetMenu: {
    opacity: 0,
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    "$widgetCard:hover &": {
      opacity: 1,
    },
    ".muuri-item-dragging $widgetCard &": {
      opacity: 0,
    },
  },
  tileContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
    color: theme.palette.text.primary,
    borderRadius: 6,
    background: theme.palette.background.paper,
    "a&:hover": {
      textDecoration: "none",
      backgroundColor: rgba(theme.palette.text.primary, 0.05),
    },
    ".muuri-item-dragging &": {
      cursor: "grab",
    },
  },
}));

export default function Widget({ widget, instance, isDraggable }) {
  const { theme: baseTheme } = useCustomTheme();
  return (
    <CustomThemeProvider>
      <WidgetThemeWrapper baseTheme={baseTheme} widgetThemes={widget?.theme}>
        <WidgetContent
          widget={widget}
          instance={instance}
          isDraggable={isDraggable}
        />
      </WidgetThemeWrapper>
    </CustomThemeProvider>
  );
}

/**
 * Uses our custom theme system, but with attributes merged in from widget
 * themes. Supports both `light` and `dark` theme objects, but will work if only
 * one is provided.
 *
 * @see @cosy/lib/integrations
 *
 * @param {object} themes - Themes provided by `widget.source`.
 * @param {?object} themes.light - Optional light theme.
 * @param {?object} themes.dark - Optional dark theme.
 */
function WidgetThemeWrapper({ children, widgetThemes, baseTheme }) {
  const { setCustomTheme } = useCustomTheme();

  const widgetTheme = useMemo(() => {
    if (!widgetThemes) {
      return baseTheme;
    }

    const newTheme = Object.assign({}, baseTheme);
    const isDarkMode = baseTheme.palette.type === "dark";

    // We want themes to be able to set primary.main and secondary.main and
    // have the other values (ie. for Button hover background) re-generated
    newTheme.palette = {
      type: baseTheme.palette.type,
    };

    const widgetTheme = isDarkMode
      ? widgetThemes.dark || widgetThemes.light
      : widgetThemes.light || widgetThemes.dark;

    return widgetTheme;
  }, [baseTheme, widgetThemes]);

  useEffect(() => {
    setCustomTheme(widgetTheme);
  }, [setCustomTheme, widgetTheme]);

  return children;
}

function WidgetContent({ widget, instance }) {
  const { workspace } = useWorkspace();
  const showCachedData = _validateCacheTimestamp(instance);
  const swrOptions = {
    revalidateOnMount: true,
  };
  if (showCachedData) {
    swrOptions.fallbackData = instance?.data;
  }

  const { data, mutate } = useSWR(
    () =>
      instance
        ? `/api/workspaces/${workspace.slug}/widgets/${instance._id}`
        : `/api/widgets/${widget.id}`,
    swrOptions
  );
  const classes = useStyles(widget?.theme);
  const [isRemoving, setIsRemoving] = useState();

  if (
    !workspace ||
    !widget ||
    !Object.hasOwnProperty.call(TEMPLATE_WIDGET_MAP, widget.template)
  ) {
    return null;
  }

  const onSettingsUpdated = () => {
    mutate(
      () =>
        fetcher(`/api/workspaces/${workspace.slug}/widgets/${instance._id}`, {
          cache: "reload",
        }),
      false
    );
  };

  const WidgetComponent = TEMPLATE_WIDGET_MAP[widget.template];

  return (
    <Box className={clsx(classes.container, { isRemoving })}>
      <Card className={classes.widgetCard}>
        {instance && (
          <WidgetMenu
            className={classes.widgetMenu}
            onRemove={() => setIsRemoving(true)}
            onSettingsUpdated={onSettingsUpdated}
            instance={instance}
          />
        )}

        <WidgetContainer className={classes.tileContainer} href={data?.href}>
          <WidgetComponent widget={widget} data={data} />
        </WidgetContainer>
      </Card>
    </Box>
  );
}

function WidgetContainer({ children, href, className }) {
  return (
    <Box
      data-testid="widget-container"
      component={href ? ButtonBase : "div"}
      href={href}
      target={href && "_blank"}
      className={className}
    >
      {children}
    </Box>
  );
}

/**
 * @private
 */
function _validateCacheTimestamp(instance) {
  if (!instance?.timestamps?.lastRefreshedAt) {
    return false;
  }

  const lastRefreshedAt = new Date(instance.timestamps.lastRefreshedAt);
  const now = new Date();

  return now.getTime() - lastRefreshedAt.getTime() <= _TWO_DAYS_MS;
}
