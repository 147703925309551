import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useEffect, useState } from "react";

export default function ActionConfirm({ options }) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setConfirmOpen(true);
  }, [options]);

  if (!options) {
    return null;
  }

  function clickOk() {
    options.onAccept();
    closeConfirm();
  }

  function closeConfirm() {
    setConfirmOpen(false);
  }

  return (
    <Dialog
      open={confirmOpen}
      onClose={closeConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{options.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {options.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeConfirm}>{options.dismiss_text}</Button>
        <Button onClick={clickOk} color="primary" autoFocus>
          {options.ok_text}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
