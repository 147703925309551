export const STATUSES = {
  ACTIVE: {
    id: "ACTIVE",
  },
  ERROR: {
    id: "ERROR",
  },
};

export const ERROR_TYPES = {
  AUTHENTICATION: {
    id: "AUTHENTICATION",
  },
  EXCEPTION: {
    id: "EXCEPTION",
  },
};
