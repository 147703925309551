import useWorkspace from "@cosy/hooks/use_workspace";

/**
 * React Hook for accessing user membership data based on the current workspace.
 *
 * @param {object} userOrMe
 * @returns {?object}
 */
export default function useMembership(userOrMe) {
  const { workspace } = useWorkspace();

  if (!workspace) {
    return null;
  }

  // Extra check for the populated version of workspace in case
  // we are using the hook on current user's membership.
  return userOrMe.memberships?.find(
    (member) =>
      member.workspace === workspace._id ||
      member.workspace._id === workspace._id
  );
}
