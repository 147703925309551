import { Grid, Link } from "@material-ui/core";

export default function WidgetIconPile({ items }) {
  return (
    <Grid container spacing={1}>
      {items.map((item) => (
        <Grid item key={item.id}>
          <Link href={item.linkUrl} title={item.linkTitle} target="_blank">
            <img
              src={item.iconSrc}
              alt={item.iconAlt}
              style={{ display: "block" }}
            />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}
