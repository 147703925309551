import { useCallback, useEffect, useRef } from "react";
import { event } from "react-fullstory";

import useSearchState from "@cosy/hooks/use_search_state";
import useSlug from "@cosy/hooks/use_slug";
import { events } from "@cosy/lib/constants/analytics";
import logger from "@cosy/lib/logger";
import { get } from "@cosy/utils/api";

const _DEBOUNCE_DURATION_MS = 500;

/**
 * React Hook for "debounced" effects on search input. We wait a
 * specified duration (eg. 500ms) after the last interaction with the search
 * input to fire:
 * 1) An update to the URL in the browser, to save the query state, and;
 * 2) Fire a fetch call in the background to fire a live search against
 * SearchSources (this doesn't yet update the actual display of results, which
 * are pulled directly from Algolia).
 */
export default function useDebouncedLivesearch() {
  const { query } = useSearchState();
  const slug = useSlug();

  const _debouncedSearch = useRef();

  const liveSearchQuery = useRef(query);
  liveSearchQuery.current = query;

  const searchLiveResults = useCallback(async () => {
    await _searchAndUpdate(slug, query, liveSearchQuery);
  }, [query, slug]);

  useEffect(() => {
    clearTimeout(_debouncedSearch.current);

    _debouncedSearch.current = setTimeout(
      searchLiveResults,
      _DEBOUNCE_DURATION_MS
    );

    return () => clearTimeout(_debouncedSearch.current);
  }, [searchLiveResults]);
}

/**
 * @private
 */
async function _searchAndUpdate(slug, query, liveSearchQuery) {
  if (!query || !slug) {
    return;
  }

  event(events.ui.SEARCH, { query });
  const response = await get(`/workspaces/${slug}/results?q=${query}`);
  logger.debug("Live search response %o", response, liveSearchQuery);

  // TODO: Notify that new results are available
  // if (query === liveSearchQuery.current && newHits) {
  //   console.log("Updating");
  //   // addHits(newHits);
  // }
}
