/**
 * Tests can be found in SearchResults.test.jsx
 */
import { Box, Typography } from "@material-ui/core";

import CustomResultAction from "@cosy/components/CustomResultAction";
import Hit from "@cosy/components/Hit";
import InternalLink from "@cosy/components/InternalLink";
import useSearchSets from "@cosy/hooks/use_search_sets";
import useSearchState from "@cosy/hooks/use_search_state";
import useSlug from "@cosy/hooks/use_slug";

export default function FallbackResultsList() {
  const {
    sets: {
      fallback: { hits: fallbackHits },
      results: { hits: resultsHits },
    },
  } = useSearchSets();
  const { page } = useSearchState();

  if (page > 0 || (fallbackHits.length === 0 && resultsHits.length > 0)) {
    return null;
  }

  return (
    <Box mt={4}>
      <FallbackResultsTitle />
      {fallbackHits.map((hit, index) => (
        <Hit hit={hit} key={hit.objectID} index={index} isFallback />
      ))}
      <Box marginTop={2}>
        <CustomResultAction />
      </Box>
    </Box>
  );
}

function FallbackResultsTitle() {
  const { query } = useSearchState();
  const { hasResults } = useSearchSets();
  const slug = useSlug();

  return hasResults ? (
    <Typography variant="h2" paragraph>
      Can’t find what you’re looking for?
    </Typography>
  ) : (
    <>
      <Typography variant="h2" paragraph>
        We couldn’t find anything for <strong>“{query}”</strong>
      </Typography>
      <Typography paragraph>
        You may want to check your spelling or{" "}
        <InternalLink href={`/${slug}/settings/search`}>
          connect a new app
        </InternalLink>
        .
      </Typography>
    </>
  );
}
