import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

import useMe from "@cosy/hooks/use_me";

export default function Clock() {
  const [date, setDate] = useState(new Date());
  const [message, setMessage] = useState("");
  const { me } = useMe();

  useEffect(() => {
    const timerID = setInterval(() => setDate(new Date()), 60000);

    return () => clearInterval(timerID);
  });

  useEffect(() => {
    const hours = date.getHours();
    const nameSuffix = me?.name ? `, ${me.name}` : "";

    if (hours > 0 && hours <= 4) {
      setMessage("Zzzz…");
    } else if (hours > 4 && hours <= 11) {
      setMessage("Good morning" + nameSuffix);
    } else if (hours > 11 && hours <= 16) {
      setMessage("Good afternoon" + nameSuffix);
    } else if ((hours > 16 || hours === 0) && hours <= 23) {
      setMessage("Good evening" + nameSuffix);
    }
  }, [date, me]);

  return (
    <div>
      <Typography
        variant="h1"
        style={{
          fontSize: "6em",
          fontWeight: 400,
          textTransform: "lowercase",
        }}
      >
        {date
          .toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" })
          .replace(/\s[ap]m/i, "")
          .split(" ")
          .join("")}
      </Typography>
      {message && (
        <Typography variant="h1" color="textSecondary" paragraph>
          {message}
        </Typography>
      )}
    </div>
  );
}
