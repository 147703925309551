/**
 * Makes Algolia Answers extracts smaller, by looking at line breaks and
 * removing any lines which are not close to a line with the highlighted answer.
 *
 * @param {string} text
 * @returns {string}
 */
export function condenseExtraLines(text) {
  const lines = text.split("\n");
  return lines
    .map((line) => line.trim())
    .filter((line) => line !== "")
    .reduce((condensedLines, line, i, arr) => {
      const previousLine = condensedLines[condensedLines.length - 1];
      const nextLine = arr[i + 1];

      if (
        _hasHighlight(line) ||
        _hasHighlight(previousLine) ||
        _hasHighlight(nextLine)
      ) {
        condensedLines.push(line);
      }

      return condensedLines;
    }, [])
    .join("\n");
}

/**
 * @private
 */
function _hasHighlight(text) {
  return !!text?.includes("<em>");
}
