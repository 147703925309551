import { ButtonBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircleRounded } from "@material-ui/icons";
import clsx from "clsx";
import { rgba } from "polished";

import InternalLink from "./InternalLink";

import useChromeExtension from "@cosy/hooks/use_chrome_extension";
import useMe from "@cosy/hooks/use_me";
import useMembership from "@cosy/hooks/use_membership";
import useWidgetSizeStyles from "@cosy/hooks/use_widget_size_styles";
import useWorkspace from "@cosy/hooks/use_workspace";
import useWorkspaceSWR from "@cosy/hooks/use_workspace_swr";
import { CHROME_EXTENSION_URL } from "@cosy/lib/constants/chrome_extension";
import { PLANS } from "@cosy/lib/constants/workspaces";

const useStyles = makeStyles((theme) => ({
  onboardingCard: {
    marginBottom: theme.spacing(2),
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderRadius: 12, // To match widgets, which have slightly larger radii
    border: `1px dashed ${rgba(theme.palette.text.secondary, 0.5)}`,
    padding: theme.spacing(2),
    "&:hover": {
      borderColor: rgba(theme.palette.text.primary, 0.6),
      "& $onboardingCardIcon": {
        color: theme.palette.text.primary,
      },
      "& $onboardingCardText": {
        color: rgba(theme.palette.text.primary, 0.8),
      },
    },
  },
  onboardingCardIcon: {
    color: theme.palette.text.secondary,
  },
  onboardingCardText: {},
}));

export default function OnboardingCards(actions) {
  const { me } = useMe();
  const { workspace } = useWorkspace();
  const membership = useMembership(me);
  const { data } = useWorkspaceSWR("/users?v=2&limit=2");
  const { canBeInstalled, isInstalled, isLoading } = useChromeExtension();
  const people = data?.users;

  const hasConnectedApps = me.connections?.some(
    (c) => c.connectedApps.length > 0
  );

  if (!hasConnectedApps) {
    return (
      <OnboardingCard href={`/${workspace.slug}/settings/apps`}>
        Connect your first app
      </OnboardingCard>
    );
  } else if (membership?.widgets.length === 0) {
    return (
      <OnboardingCard onClick={actions.onAddWidget}>
        Add your first widget
      </OnboardingCard>
    );
  } else if (workspace.searchSources.length === 0) {
    return (
      <OnboardingCard href={`/${workspace.slug}/settings/apps`}>
        Add a search source
      </OnboardingCard>
    );
  } else if (workspace.plan === PLANS.TEAM.id && people?.length === 1) {
    return (
      <OnboardingCard href={`/${workspace.slug}/people`}>
        Invite some team members
      </OnboardingCard>
    );
  } else if (canBeInstalled && !isLoading && !isInstalled) {
    return (
      <OnboardingCard href={CHROME_EXTENSION_URL}>
        Get the Chrome Extension
      </OnboardingCard>
    );
  } else {
    return null;
  }
}

function OnboardingCard({ href, onClick, children }) {
  const classes = useStyles();
  const widgetClasses = useWidgetSizeStyles();

  return (
    <InternalLink
      href={href}
      linkComponent={ButtonBase}
      linkProps={{
        className: clsx(classes.onboardingCard, widgetClasses.medium),
        focusRipple: true,
        onClick:
          onClick &&
          function (e) {
            // Don't follow links if onClick is provided
            e.preventDefault();
            onClick();
          },
      }}
    >
      <AddCircleRounded
        fontSize="small"
        titleAccess="Add"
        className={classes.onboardingCardIcon}
      />
      <Typography
        variant="button"
        color="textSecondary"
        align="left"
        className={classes.onboardingCardText}
      >
        {children}
      </Typography>
    </InternalLink>
  );
}
