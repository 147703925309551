import { CardMedia, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { rgba } from "polished";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  cardMedia: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  titleBar: {
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    right: 0,
    left: 0,
    background: `linear-gradient(to top, ${rgba(
      theme.palette.background.default,
      0.5
    )} 0%, ${rgba(theme.palette.background.default, 0.6)} 90%, ${rgba(
      theme.palette.background.default,
      0.6
    )} 100%)`,
    padding: theme.spacing(2),
  },
}));

export default function WidgetImageSmall({ data }) {
  const hasData = data !== undefined;

  const classes = useStyles();
  return (
    <Fragment>
      {hasData && (
        <CardMedia
          image={data.src}
          title={data.text}
          className={classes.cardMedia}
        />
      )}

      <div className={classes.titleBar}>
        <Typography variant="body2" noWrap>
          {hasData ? data.text : <Skeleton width={80} />}
        </Typography>
      </div>
    </Fragment>
  );
}
