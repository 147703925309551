import { Pagination } from "@material-ui/lab";

import useSearchSets from "@cosy/hooks/use_search_sets";
import useSearchState from "@cosy/hooks/use_search_state";

export default function SearchPagination() {
  const { page, setPage } = useSearchState();
  const {
    sets: {
      results: { totalPages },
    },
  } = useSearchSets();

  if (totalPages < 2) {
    return null;
  }

  function handleChange(e, page) {
    setPage(page - 1);
  }

  return (
    <Pagination
      count={totalPages}
      page={page + 1}
      size="large"
      onChange={handleChange}
    />
  );
}
