import { Button } from "@material-ui/core";
import { useState } from "react";

import CustomResultAddModal from "@cosy/components/CustomResultAddModal";
import usePreviousHotKeysScope from "@cosy/hooks/use_previous_hotkeys_scope";

export default function CustomResultButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { changeScope, resetScope } = usePreviousHotKeysScope();

  const onClick = () => {
    setIsModalOpen(true);
    changeScope("customResultModal");
  };

  const onClose = () => {
    setIsModalOpen(false);
    resetScope();
  };

  return (
    <>
      <Button color="primary" size="large" onClick={onClick}>
        Or suggest a new result…
      </Button>
      <CustomResultAddModal isOpen={isModalOpen} onClose={onClose} />
    </>
  );
}
