import { Typography, Tooltip } from "@material-ui/core";

import useMe from "@cosy/hooks/use_me";
import useMembership from "@cosy/hooks/use_membership";
import { getLocalTime } from "@cosy/utils/datetime";

export default function LocalTime({ userMembership, withHyphen = false }) {
  const { me } = useMe();
  const meMembership = useMembership(me);
  const isSameTimezone =
    userMembership?.profile?.timeZone === meMembership?.profile?.timeZone;

  if (!userMembership?.profile?.timeZone || isSameTimezone) {
    return null;
  }

  return (
    <Tooltip
      title={`GMT ${getLocalTime(userMembership?.profile?.timeZone)?.offset}`}
      placement="bottom"
    >
      <Typography component="span" variant="body2" color="textSecondary">
        {withHyphen && " — "} Local time:{" "}
        {getLocalTime(userMembership?.profile?.timeZone)?.time}
      </Typography>
    </Tooltip>
  );
}
