import { Box, Typography } from "@material-ui/core";
import { useEffect } from "react";

import ActionButtons from "./ActionButtons";
import Blocks from "./Blocks";
import HitIcon from "./HitIcon";

import useHitsFocusModel from "@cosy/hooks/use_hits_focus_model";
import logger from "@cosy/lib/logger";

/**
 * Shows a Preview Card for the currently focused search result hit.
 */
export default function PreviewPane() {
  const { focusedHit } = useHitsFocusModel();

  useEffect(() => {
    if (focusedHit) {
      logger.debug("Showing preview: %o", focusedHit);
    }
  }, [focusedHit]);

  if (!focusedHit) {
    return null;
  }

  if (!focusedHit.previewBlocks || focusedHit.previewBlocks.length === 0) {
    return (
      <Box className="empty-preview" p={4} overflow="hidden" textAlign="center">
        <div className="icon-container">
          <HitIcon style={{ margin: "0 auto" }} hit={focusedHit} size={48} />
        </div>

        <Typography variant="h1">{focusedHit.title}</Typography>
        <Typography variant="body1">{focusedHit.description}</Typography>
        <style jsx>{`
          .icon-container {
            margin: 24px auto 12px;
            display: inline-block;
          }
        `}</style>
      </Box>
    );
  }

  return (
    <div style={{ padding: 32, maxHeight: "60vh", overflowY: "auto" }}>
      <Blocks blocks={focusedHit.previewBlocks} />
      <ActionButtons hit={focusedHit} />
    </div>
  );
}
