import hotkeys from "hotkeys-js";
import { useRef } from "react";

/**
 * Hook to allow temporarily overriding the hotkeys.js scope, then setting it
 * back.
 *
 * @returns {*}
 */
export default function usePreviousHotKeysScope() {
  const previousHotkeysScope = useRef(hotkeys.getScope());
  const changeScope = (newValue) => {
    previousHotkeysScope.current = hotkeys.getScope();
    hotkeys.setScope(newValue);
  };

  const resetScope = () => {
    hotkeys.setScope(previousHotkeysScope.current);
  };

  return { changeScope, resetScope };
}
