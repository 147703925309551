import { makeStyles } from "@material-ui/core/styles";

export const BASE_WIDGET_SIZE = 85;

/**
 * Hook that generates proper classname definitions for widgets.
 * 
 @returns {*}
 */
export default function useWidgetSizeStyles() {
  const useStyles = makeStyles((theme) => ({
    mini: {
      width: BASE_WIDGET_SIZE,
      height: BASE_WIDGET_SIZE,
    },
    small: {
      width: BASE_WIDGET_SIZE * 2 + theme.spacing(2),
      height: BASE_WIDGET_SIZE * 2 + theme.spacing(2),
    },
    medium: {
      width: BASE_WIDGET_SIZE * 4 + theme.spacing(2) * 3,
      height: BASE_WIDGET_SIZE * 2 + theme.spacing(2),
    },
    wrapper: {
      maxWidth: BASE_WIDGET_SIZE * 4 + theme.spacing(2) * 4,
    },
  }));

  const classes = useStyles();
  return classes;
}
