import { useTheme } from "@material-ui/core/styles";
import { darken } from "polished";
import {
  RadialBarChart,
  PolarAngleAxis,
  RadialBar,
  Customized,
} from "recharts";

const _CIRCLE_SIZE = 52;
const _RADIUS = _CIRCLE_SIZE / 2;
const _STROKE_WIDTH = 6;

function RadialProgressImage({ src }) {
  return (
    <foreignObject
      x={_RADIUS / 2}
      y={_RADIUS / 2}
      width={_RADIUS}
      height={_RADIUS}
    >
      <img src={src} alt="" width={_RADIUS} height={_RADIUS} />
    </foreignObject>
  );
}

export default function RadialProgress({ value, imageSrc }) {
  const theme = useTheme();

  return (
    <RadialBarChart
      width={_CIRCLE_SIZE}
      height={_CIRCLE_SIZE}
      cx={_RADIUS}
      cy={_RADIUS}
      innerRadius={_RADIUS - _STROKE_WIDTH / 2}
      outerRadius={_RADIUS - _STROKE_WIDTH / 2}
      margin={0}
      barSize={_STROKE_WIDTH}
      data={[{ value }]}
      startAngle={90}
      endAngle={-270}
    >
      {imageSrc && (
        <Customized
          key={imageSrc}
          component={() => <RadialProgressImage src={imageSrc} />}
        />
      )}
      <PolarAngleAxis
        type="number"
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
      />
      <RadialBar
        background={{ fill: darken(0.05, theme.palette.background.paper) }}
        clockWise
        dataKey="value"
        cornerRadius={_CIRCLE_SIZE / 2}
        fill={theme.palette.text.primary}
      />
    </RadialBarChart>
  );
}
