/**
 * Replaces line breaks in text.
 *
 * @param {string} text
 * @param {string} [replacement=" "]
 * @returns {string}
 */
export function replaceLineBreaks(text, replacement = " ") {
  return text.replace(/\r\n|\r|\n/g, replacement);
}
