/**
 * Taken and modified from
 * https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-core/src/core/highlight.js.
 */

/**
 * @private
 */
function _parseHighlightedAttribute({
  preTag,
  postTag,
  highlightedValue = "",
}) {
  const splitByPreTag = highlightedValue.split(preTag);
  const firstValue = splitByPreTag.shift();
  const elements =
    firstValue === "" ? [] : [{ value: firstValue, isHighlighted: false }];

  splitByPreTag.forEach((split) => {
    const splitByPostTag = split.split(postTag);

    elements.push({
      value: splitByPostTag[0],
      isHighlighted: true,
    });

    if (splitByPostTag[1] !== "") {
      elements.push({
        value: splitByPostTag[1],
        isHighlighted: false,
      });
    }
  });

  return elements;
}

/**
 * Find an highlighted attribute given an `attribute` and an
 * `highlightProperty`, parses it, and provided an array of objects with the
 * string value and a boolean if this value is highlighted.
 *
 * In order to use this feature, highlight must be activated in the
 * configuration of the index. The `preTag` and `postTag` attributes are
 * respectively highlightPreTag and highlightPostTag in Algolia configuration.
 *
 * @param {object} options
 * @param {string} options.preTag - String used to identify the start of an
 * highlighted value.
 * @param {string} options.postTag - String used to identify the end of an
 * highlighted value.
 * @param {string} options.highlightProperty - The property that contains the
 * highlight structure in the results.
 * @param {string} options.attribute - The highlighted attribute to look for.
 * @param {object} options.hit - The actual hit returned by Algolia.
 * @returns {object[]} - An array of {value: string, isHighlighted: boolean}.
 */
export function parseAlgoliaHit({
  preTag = "<strong>",
  postTag = "</strong>",
  highlightProperty = "_highlightResult",
  attribute,
  hit,
}) {
  if (!hit) {
    throw new Error("`hit`, the matching record, must be provided");
  }

  if (!hit[highlightProperty]) {
    throw new Error("`hit` missing the highlighted property");
  }

  const highlightObject = hit[highlightProperty][attribute] || {};

  return _parseHighlightedAttribute({
    preTag,
    postTag,
    highlightedValue: highlightObject.value,
  });
}
