/**
 * React component for rendering small upnext widget.
 *
 * @typedef {Object} data
 * @property {Object} event
 * @property {String} title
 * @property {String} noneText
 *
 * @typedef {Object} File
 * @property {String} id
 * @property {String} name
 * @property {String} mimeType
 * @property {String} url
 * @property {String} iconSrc
 */

import { Box, Typography, Button, Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

import WidgetIconPile from "@cosy/components/WidgetIconPile";
import { formatReadableTimeRange } from "@cosy/utils/datetime";

export default function WidgetUpNextSmall({ data }) {
  const isLoading = data === undefined;

  return (
    <Box
      display="flex"
      padding={2}
      minWidth={0}
      width="100%"
      justifySelf="stretch"
    >
      {!isLoading && !data.event ? (
        <Box flex="4">
          <Typography variant="body2" color="textSecondary">
            {data.noneText}
          </Typography>
        </Box>
      ) : (
        <EventContent isLoading={isLoading} event={data?.event} />
      )}
    </Box>
  );
}

function EventContent({ isLoading, event }) {
  return (
    <Box alignSelf="flex-end" width="100%">
      <Typography variant="body1" noWrap color="textPrimary">
        {!isLoading ? (
          <strong>
            <Link href={event.eventUrl} target="_blank">
              {event.topic}
            </Link>
          </strong>
        ) : (
          <Skeleton width={40} />
        )}
      </Typography>
      {!isLoading ? (
        <>
          <Typography variant="body2" color="textSecondary" noWrap paragraph>
            {_getInfo(event)}
          </Typography>
          {event.files && (
            <WidgetIconPile items={event.files.map(_formatFileAsIconPile)} />
          )}
        </>
      ) : (
        <Skeleton width={60} />
      )}
      {!isLoading && event.meetingUrl && (
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            href={event.meetingUrl}
            target="_blank"
            fullWidth
          >
            Join meeting
          </Button>
        </Box>
      )}
    </Box>
  );
}

/**
 * Returns the info text of Up Next meeting for use in a widget.
 *
 * @private
 */
function _getInfo(event) {
  const startTime = new Date(event.startTime);
  const endTime = new Date(event.endTime);

  return formatReadableTimeRange(startTime, endTime);
}

/**
 * @private
 */
function _formatFileAsIconPile({ id, name, mimeType, url, iconSrc }) {
  return {
    id,
    linkUrl: url,
    linkTitle: name,
    iconSrc,
    iconAlt: `${mimeType} file`,
  };
}
