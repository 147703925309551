import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { rgba } from "polished";
import { forwardRef } from "react";

const useStyles = makeStyles((theme) => ({
  hit: {
    marginBottom: theme.spacing(1),
    backgroundColor: rgba(theme.palette.background.paper, 0.5),
  },
  hitFocused: {
    transform: "translateY(-1px)",
    backgroundColor: theme.palette.background.paper,
  },
  hitContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
}));

export default forwardRef(function HitCard(
  { children, isFocused, className, ...otherProps },
  ref
) {
  const styles = useStyles();

  return (
    <Card
      className={clsx(styles.hit, className, {
        [styles.hitFocused]: isFocused,
      })}
      ref={ref}
      elevation={isFocused ? 1 : 0}
      {...otherProps}
    >
      <CardContent className={styles.hitContent}>{children}</CardContent>
    </Card>
  );
});
