import { Box, IconButton, Menu } from "@material-ui/core";
import { MoreVert, RemoveCircleRounded, TuneRounded } from "@material-ui/icons";
import { useState } from "react";

import InternalMenuItem from "@cosy/components/InternalMenuItem";
import useBlocksModal from "@cosy/hooks/use_blocks_modal";
import useMe from "@cosy/hooks/use_me";
import useSlug from "@cosy/hooks/use_slug";
import useWorkspace from "@cosy/hooks/use_workspace";
import useWorkspaceSWR from "@cosy/hooks/use_workspace_swr";
import { del } from "@cosy/utils/api";

export default function WidgetMenu({
  instance,
  onRemove,
  className,
  onSettingsUpdated,
}) {
  const [anchorEl, setAnchorEl] = useState();
  const slug = useSlug();

  const { workspace } = useWorkspace();
  const { mutate: mutateMe } = useMe();

  const { data: settings, mutate: mutateWidgetSettings } = useWorkspaceSWR(
    `/widgets/${instance._id}/settings`
  );

  const { openModal } = useBlocksModal();

  function showSettings() {
    openModal(
      settings,
      `/workspaces/${slug}/widgets/${instance._id}/settings`,
      {
        optionsLoadURL: `/workspaces/${slug}/widgets/${instance._id}/options`,
        onSubmittedCallback: () => {
          mutateWidgetSettings();
          onSettingsUpdated();
        },
      }
    );
    closeMenu();
  }

  const openMenu = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  async function deleteWidget() {
    if (onRemove && typeof onRemove === "function") {
      onRemove();
    }

    closeMenu();
    await del(`/workspaces/${workspace.slug}/widgets/${instance._id}`);
    mutateMe();
  }

  if (!workspace) {
    return null;
  }

  return (
    <Box display="flex">
      <IconButton
        aria-controls="widget-menu"
        aria-haspopup="true"
        onClick={openMenu}
        className={className}
        size="small"
      >
        <MoreVert titleAccess="Widget actions" fontSize="small" />
      </IconButton>
      <Menu
        id="widget-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {settings && (
          <InternalMenuItem onClick={showSettings} iconComponent={TuneRounded}>
            Settings
          </InternalMenuItem>
        )}
        <InternalMenuItem
          onClick={deleteWidget}
          iconComponent={RemoveCircleRounded}
          isDestructive
        >
          Remove
        </InternalMenuItem>
      </Menu>
    </Box>
  );
}
