import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LinkRounded } from "@material-ui/icons";
import { useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useController,
  useWatch,
} from "react-hook-form";
import { useAsyncFn } from "react-use";

import useMe from "@cosy/hooks/use_me";
import useSearchState from "@cosy/hooks/use_search_state";
import useWorkspace from "@cosy/hooks/use_workspace";
import { VISIBILITY_SCOPE_TYPES } from "@cosy/lib/constants/results";
import { FEATURES } from "@cosy/lib/constants/workspaces";
import { post, get } from "@cosy/utils/api";

const _STEPS = {
  URL_ENTRY: 1,
  DETAILS_ENTRY: 2,
};

const useStyles = makeStyles((theme) => ({
  thumbnailIcon: {
    margin: "auto",
    marginTop: theme.spacing(1),
    width: 48,
    height: 48,
  },
}));

export default function CustomResultAddModal({ isOpen, onClose }) {
  const { currentStep, onExit, onSubmit, isLoading, formMethods } =
    _useMultiStepForm(onClose);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="dialog-title"
      fullWidth
      TransitionProps={{
        onExited: onExit,
      }}
    >
      <DialogTitle id="dialog-title">Add a result</DialogTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <DialogContent>
            {currentStep === _STEPS.URL_ENTRY ? (
              <UrlField />
            ) : (
              <DetailsFields />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <SubmitButton currentStep={currentStep} isLoading={isLoading} />
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}

function SubmitButton({ currentStep, isLoading }) {
  const { workspace } = useWorkspace();
  return (
    <Button type="submit" color="primary" disabled={!workspace || isLoading}>
      {currentStep === _STEPS.URL_ENTRY
        ? isLoading
          ? "Fetching…"
          : "Next"
        : isLoading
        ? "Saving…"
        : "Save"}
    </Button>
  );
}

function UrlField() {
  return (
    <Input
      name="url"
      label="Type or paste a URL"
      required
      onErrorMsg="You need to enter a URL"
    />
  );
}

function DetailsFields() {
  const { workspace } = useWorkspace();

  const hasTeamFeatures = workspace?.features.some(
    (f) => f.id === FEATURES.MEMBERS.id
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={10}>
          <Input name="title" label="Title" required />
          <Input name="description" label="Description" multiline rows={3} />
        </Grid>
        <Grid item sm={2}>
          <Icon />
        </Grid>
      </Grid>
      {hasTeamFeatures && (
        <Box mt={2}>
          <VisibilitySelect>
            <MenuItem value={VISIBILITY_SCOPE_TYPES.MEMBERSHIP}>
              Just me
            </MenuItem>
            <MenuItem value={VISIBILITY_SCOPE_TYPES.WORKSPACE}>
              All of {workspace.name}
            </MenuItem>
          </VisibilitySelect>
        </Box>
      )}
    </>
  );
}

function Input({
  name,
  label,
  required,
  defaultValue,
  multiline,
  rows,
  onErrorMsg,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: defaultValue || "",
  });

  const errorMsg =
    onErrorMsg ||
    `${name.slice(0, 1).toUpperCase()}${name.slice(1)} is required`;

  return (
    <FormControl fullWidth margin="dense" required={required}>
      <TextField
        {...inputProps}
        onChange={(e) => onChange(e.target.value)}
        inputRef={ref}
        multiline={multiline}
        label={label}
        id={`custom-result-form-${name}`}
        rows={rows}
        error={!!errors[name]}
        helperText={errors[name] ? errorMsg : ""}
      />
    </FormControl>
  );
}

function VisibilitySelect({ children }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: "visibilityScope",
    control,
    rules: { required: true },
    defaultValue: VISIBILITY_SCOPE_TYPES.MEMBERSHIP,
  });
  return (
    <TextField
      {...inputProps}
      select
      inputRef={ref}
      label="Visibility"
      helperText="Who will be able to see this result while searching?"
      defaultValue={VISIBILITY_SCOPE_TYPES.MEMBERSHIP}
    >
      {children}
    </TextField>
  );
}

function Icon() {
  const { control } = useFormContext();
  const icon = useWatch({
    control,
    name: "icon",
  });

  const classes = useStyles();

  return (
    <Avatar variant="square" src={icon} className={classes.thumbnailIcon}>
      <LinkRounded />
    </Avatar>
  );
}

function _useMultiStepForm(onClose) {
  const [currentStep, setCurrentStep] = useState(_STEPS.URL_ENTRY);
  const { mutate: mutateMe } = useMe();
  const { query } = useSearchState();
  const { fetchUrlMetadata, loading: loadingMetadata } = _useFetchUrlMetadata();
  const { saveResult, loading: savingResults } = _useSaveResult();
  const methods = useForm();
  const { setValue, reset } = methods;

  const onExit = () => {
    reset();
    setCurrentStep(_STEPS.URL_ENTRY);
  };

  const onSubmit = async (values) => {
    if (currentStep === _STEPS.URL_ENTRY) {
      const metadata = await fetchUrlMetadata(values.url);
      if (metadata) {
        setValue("title", metadata.title);
        setValue("description", metadata.description);
        setValue("icon", metadata.icon);
        setValue("image", metadata.image);
        setValue("date", metadata.date);
      }
      setCurrentStep(_STEPS.DETAILS_ENTRY);
    } else {
      await saveResult({ ...values, query });
      mutateMe();
      onClose();
    }
  };

  return {
    currentStep,
    onExit,
    onSubmit,
    isLoading: loadingMetadata || savingResults,
    formMethods: methods,
  };
}

function _useFetchUrlMetadata() {
  const [{ loading }, fetchUrlMetadata] = useAsyncFn(async (url) => {
    const { data } = await get(`/link-metadata?url=${encodeURIComponent(url)}`);
    return data;
  }, []);

  return {
    fetchUrlMetadata,
    loading,
  };
}

function _useSaveResult() {
  const { workspace } = useWorkspace();
  const [{ loading }, saveResult] = useAsyncFn(
    async (values) => {
      await post(`/workspaces/${workspace.slug}/results`, values);
    },
    [workspace]
  );

  return {
    saveResult,
    loading,
  };
}
