import { STATUSES } from "@cosy/lib/constants/memberships";

/**
 * Returns whether a user's membership is allowed to delete a result.
 *
 * @param {object} membership
 * @param {string} membership.id
 * @param {object} resultVisibility
 * @param {string} resultVisibility.scopeType
 * @param {string} resultVisibility.scopeID
 * @returns {boolean}
 */
export function canDeleteResult(membership, resultVisibility) {
  const { scopeType, scopeID } = resultVisibility;
  return (
    (scopeType === "Membership" && scopeID === membership.id) ||
    (scopeType === "Workspace" && membership.status === STATUSES.ACTIVE.id)
  );
}
