import { useAsync } from "react-use";

import useAlgoliaIndex from "@cosy/hooks/use_algolia_index";
import useSearchState from "@cosy/hooks/use_search_state";
import { ACTION_SUB_TYPES, FACETS } from "@cosy/lib/constants/results";
import logger from "@cosy/lib/logger";

const _IGNORED_FACET_VALUES = {
  [FACETS.SOURCE]: ["Cosy"],
  [FACETS.SUB_TYPE]: Object.values(ACTION_SUB_TYPES),
};

/**
 * Hook for components to get Algolia facet values for source
 * based on the current query.
 *
 * @returns {object}
 */
export function useSourceFacetValues() {
  return _useAlgoliaFacetValues(FACETS.SOURCE);
}

/**
 * Hook for components to get Algolia facet values for subType
 * based on the current query.
 *
 * @returns {object}
 */
export function useSubTypeFacetValues() {
  return _useAlgoliaFacetValues(FACETS.SUB_TYPE);
}

/**
 * Hook that handles Algolia facets, fetching and returning proper data.
 *
 * @param {string} facetName
 * @returns {object}
 */
function _useAlgoliaFacetValues(facetName) {
  const index = useAlgoliaIndex();
  const {
    query,
    isBrowseMode,
    searchParams: { facetFilters },
  } = useSearchState();

  const { value, loading } = useAsync(async () => {
    const shouldFetch = index && (query.length > 0 || isBrowseMode);

    if (!shouldFetch) {
      logger.debug(
        "Conditions to search facet values are not met, early returning"
      );
      return null;
    }

    logger.debug("Searching %s facet values for “%s”", facetName, query);
    return index.searchForFacetValues(facetName, "", {
      query,
      facetFilters: facetFilters.filter(
        (ff) =>
          ff.length > 0 && !ff.some((filter) => filter.includes(facetName))
      ),
    });
  }, [index, query, isBrowseMode, facetFilters]);

  const facetHits = value?.facetHits.filter(
    (facetHit) => !_IGNORED_FACET_VALUES[facetName].includes(facetHit.value)
  );

  return {
    facetHits: facetHits || [],
    isLoading: loading,
  };
}
