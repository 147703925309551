/**
 * Tests can be found in SearchResults.test.jsx
 */
import { Grid, Link, Typography } from "@material-ui/core";

import HitCard from "@cosy/components/HitCard";
import HitIcon from "@cosy/components/HitIcon";
import HitMenu from "@cosy/components/HitMenu";
import Markdown from "@cosy/components/Markdown";
import useHitsFocusModel, {
  useFocusableHit,
} from "@cosy/hooks/use_hits_focus_model";
import usePerformAction from "@cosy/hooks/use_perform_action";
import useSearchState from "@cosy/hooks/use_search_state";
import { CUSTOM_SUB_TYPES } from "@cosy/lib/constants/results";
import { replaceLineBreaks } from "@cosy/lib/formatters/line-breaks";
import { parseAlgoliaHit } from "@cosy/utils/parse_algolia_hit";

export default function Hit({ hit, index, isFallback }) {
  const { setFocusedHit } = useHitsFocusModel();
  const { isFocused, ref } = useFocusableHit(hit);

  return (
    <HitCard
      ref={ref}
      isFocused={isFocused}
      data-testid={`${isFallback ? "fallback" : "results"}-row-${index + 1}`}
      onMouseMove={() => setFocusedHit(hit)}
    >
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item zeroMinWidth style={{ flexShrink: 0, lineHeight: 0 }}>
          <HitIcon hit={hit} />
        </Grid>
        <Grid item zeroMinWidth>
          <Typography variant="h6" noWrap>
            <HitTitle hit={hit} isFallback={isFallback} />
          </Typography>
          <HitDescription hit={hit} />
          {hit.meta?.lastModified && (
            <Typography variant="body2" color="textSecondary" noWrap>
              Updated{" "}
              {new Date(hit.meta.lastModified).toLocaleDateString("en-US")}{" "}
            </Typography>
          )}
        </Grid>
        <Grid item style={{ marginLeft: "auto", alignSelf: "center" }}>
          {hit.subType === CUSTOM_SUB_TYPES.BOOKMARK && <HitMenu hit={hit} />}
        </Grid>
      </Grid>
    </HitCard>
  );
}

function HitTitle({ hit, isFallback }) {
  const performAction = usePerformAction(isFallback);
  return (
    <Link
      href={hit.url || "#"}
      color="inherit"
      rel="noopener"
      onClick={(e) => {
        performAction(hit);
        if (hit.type !== "url") {
          e.preventDefault();
        }
      }}
    >
      <HitTitleText hit={hit} isFallback={isFallback} />
    </Link>
  );
}

function HitDescription({ hit }) {
  if (
    ["partial", "full"].includes(hit._snippetResult?.text?.matchLevel) &&
    hit._snippetResult?.text.value
  ) {
    // We use the Markdown component as Algolia returns w/strong tags, and this
    // ensures it's inserted into the DOM safely
    return (
      <Markdown
        text={replaceLineBreaks(hit._snippetResult.text.value)}
        variant="body2"
        noWrap
        inline
        style={{ display: "block" }}
      />
    );
  } else if (hit.description) {
    return (
      <Typography variant="body2" noWrap>
        {hit.description.substring(0, 140)}
      </Typography>
    );
  }

  return null;
}

function HitTitleText({ hit, isFallback }) {
  const { query } = useSearchState();

  if (hit.titlePattern) {
    const actionValue = isFallback ? query : _getActionValue(hit, query);

    if (actionValue) {
      return hit.titlePattern.replace("{query}", actionValue);
    }
  }

  if (!hit._highlightResult) {
    return hit.title;
  }

  return parseAlgoliaHit({ hit, attribute: "title" }).map(
    ({ value, isHighlighted }, i) => {
      if (isHighlighted) {
        return <strong key={i}>{value}</strong>;
      } else {
        return value;
      }
    }
  );
}

function _getActionValue(hit, query) {
  const actionRe = new RegExp("^" + hit.keyword + " +", "i");
  return query.match(actionRe) ? query.replace(actionRe, "") : "";
}
