import { Box, ButtonBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import UserAvatar from "./UserAvatar";

import HitCard from "@cosy/components/HitCard";
import InternalLink from "@cosy/components/InternalLink";
import LocalTime from "@cosy/components/LocalTime";
import { useFocusableHit } from "@cosy/hooks/use_hits_focus_model";
import useMembership from "@cosy/hooks/use_membership";
import useSearchSets from "@cosy/hooks/use_search_sets";
import useSearchState from "@cosy/hooks/use_search_state";
import useSlug from "@cosy/hooks/use_slug";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "start",
    overflowX: "auto",
    marginBottom: theme.spacing(4),
    width: "100%",
    padding: theme.spacing(2),
    marginRight: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
  personCard: {
    flexShrink: 0,
    marginRight: theme.spacing(2),
    width: 200,
  },
  personLink: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

function PersonCard({ user }) {
  const { slug } = useSlug();
  const classes = useStyles();
  const membership = useMembership(user);
  const { isFocused, ref } = useFocusableHit(user);
  const personHref = `/${slug}/people/${user._id}`;

  if (!membership) {
    return null;
  }

  return (
    <HitCard ref={ref} isFocused={isFocused} className={classes.personCard}>
      <InternalLink
        href={personHref}
        linkComponent={ButtonBase}
        linkProps={{ className: classes.personLink }}
      >
        <UserAvatar user={user} />
        <Box my={1}>
          <Typography variant="body1" noWrap>
            {membership.displayName}
          </Typography>
          {membership.profile.role && (
            <Typography variant="body2" color="textSecondary" noWrap>
              {membership.profile.role}
            </Typography>
          )}
        </Box>
        {membership.profile.phoneNumber && (
          <Typography variant="body2" color="textSecondary" noWrap>
            {membership.profile.phoneNumber}
          </Typography>
        )}
        <LocalTime userMembership={membership} />
        <Typography variant="body2" color="textSecondary" noWrap>
          {user.email}
        </Typography>
      </InternalLink>
    </HitCard>
  );
}

export default function PeopleResultsList() {
  const {
    sets: {
      people: { hits },
    },
  } = useSearchSets();
  const { page } = useSearchState();
  const classes = useStyles();

  if (page > 0 || hits.length === 0) {
    return null;
  }

  return (
    <div className={classes.container}>
      {hits.map((user) => (
        <PersonCard key={user._id} user={user} />
      ))}
    </div>
  );
}
