import { Box, Button, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FallbackResultsList from "@cosy/components/FallbackResultsList";
import Hit from "@cosy/components/Hit";
import HitCard from "@cosy/components/HitCard";
import HitIcon from "@cosy/components/HitIcon";
import Markdown from "@cosy/components/Markdown";
import PeopleResultsList from "@cosy/components/PeopleResultsList";
import SearchPagination from "@cosy/components/SearchPagination";
import useHitsFocusModel, {
  useFocusableHit,
} from "@cosy/hooks/use_hits_focus_model";
import useSearchSets from "@cosy/hooks/use_search_sets";
import useSearchState from "@cosy/hooks/use_search_state";
import { condenseExtraLines } from "@cosy/lib/formatters/algolia-answer";

const useStyles = makeStyles((theme) => ({
  answerText: {
    color: theme.palette.text.secondary,
    "& em": {
      fontStyle: "normal",
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.primary,
    },
  },
  answerIcon: {
    marginRight: theme.spacing(1),
    display: "inline-block",
  },
}));

export default function SearchResults() {
  const { isBrowseMode } = useSearchState();
  const {
    sets: {
      results: { totalPages },
    },
  } = useSearchSets();

  return (
    <div>
      <Answer />
      <ResultsList />
      <PeopleResultsList />
      <FallbackResultsList />
      {(totalPages > 1 || isBrowseMode) && (
        <Box mt={6} display="flex" alignItems="center">
          <SearchPagination />
          {isBrowseMode && <ExitBrowseModeButton />}
        </Box>
      )}
    </div>
  );
}

function Answer() {
  const { setFocusedHit } = useHitsFocusModel();
  const styles = useStyles();
  const {
    searchParams: { facetFilters },
  } = useSearchState();
  const {
    sets: {
      answers: { isLoading, hits },
    },
  } = useSearchSets();
  const { isFocused, ref } = useFocusableHit(hits?.[0]);

  if (isLoading || facetFilters.length > 0 || hits.length === 0) {
    return null;
  }

  const [hit] = hits;
  const text = condenseExtraLines(hit._answer.extract);

  return (
    <Box mb={2}>
      <HitCard
        variant="outlined"
        data-testid="answer-row"
        isFocused={isFocused}
        onMouseMove={() => setFocusedHit(hit)}
        ref={ref}
      >
        <Markdown text={text} variant="body1" className={styles.answerText} />
        <Box mt={2} display="flex" alignItems="center">
          <Box className={styles.answerIcon}>
            <HitIcon hit={hit} />
          </Box>

          <Typography variant="h6" noWrap>
            <Link href={hit.url || "#"} rel="noopener" color="inherit">
              {hit.title}
            </Link>
          </Typography>
        </Box>
      </HitCard>
    </Box>
  );
}

function ResultsList() {
  const {
    sets: { results },
  } = useSearchSets();

  if (results.hits.length === 0) {
    return null;
  }

  return (
    <Box mb={2}>
      {results.hits.map((hit, index) => (
        <Hit hit={hit} key={hit.objectID} index={index} />
      ))}
    </Box>
  );
}

function ExitBrowseModeButton() {
  const { setIsBrowseMode } = useSearchState();
  return (
    <Box marginLeft="auto">
      <Button variant="outlined" onClick={() => setIsBrowseMode(false)}>
        Exit Browse Mode
      </Button>
    </Box>
  );
}
