import {
  Box,
  Button,
  Drawer,
  GridList,
  GridListTile,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { rgba } from "polished";
import useSWR from "swr";

import Widget from "./Widget";

import InternalLink from "@cosy/components/InternalLink";
import useMe from "@cosy/hooks/use_me";
import useMembership from "@cosy/hooks/use_membership";
import useWorkspace from "@cosy/hooks/use_workspace";
import { post } from "@cosy/utils/api";

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "flex-stretch",
    alignItems: "flex-stretch",
    "&:hover $widgetAddContainer": {
      opacity: 1,
    },
  },
  widgetAddContainer: {
    opacity: 0,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: rgba(theme.palette.background.default, 0.6),
    zIndex: 100,
  },
}));

export default function WidgetDrawer({ open, onClose }) {
  const { workspace } = useWorkspace();
  const { me, mutate } = useMe();
  const membership = useMembership(me);
  const { data: widgets } = useSWR("/api/widgets");

  const classes = useStyles();

  if (!workspace) {
    return null;
  }

  async function addWidget(widget) {
    const { data } = await post(`/workspaces/${workspace.slug}/widgets`, {
      widget: widget.id,
    });

    membership.widgets.push(data);
    const otherMemberships = me.memberships.filter(
      (m) => m._id !== membership._id
    );

    mutate({ ...me, memberships: [...otherMemberships, membership] }, false);
    onClose();
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        style: { backgroundColor: "transparent" },
      }}
      transitionDuration={160}
    >
      <Box width={400} p={3}>
        <Typography variant="h3" paragraph>
          Widgets
        </Typography>

        {widgets?.length === 0 && (
          <Typography paragraph>
            Connect your favorite apps to add widgets to your workspace
            dashboard.
          </Typography>
        )}

        <GridList cellHeight={160} spacing={16} cols={2}>
          {widgets?.map((widget) => (
            <GridListTile cols={SIZE_COLUMN_MAP[widget.size]} key={widget.id}>
              <div className={classes.widgetContainer}>
                <Widget widget={widget} />
                <div className={classes.widgetAddContainer}>
                  <Button onClick={() => addWidget(widget)}>Add</Button>
                </div>
              </div>
            </GridListTile>
          ))}
        </GridList>
        <Box my={2}>
          <InternalLink
            href={`/${workspace.slug}/settings/apps`}
            linkComponent={Button}
            linkProps={{
              fullWidth: true,
              variant: "contained",
              color: "primary",
            }}
          >
            Connect more apps…
          </InternalLink>
        </Box>
      </Box>
    </Drawer>
  );
}

const SIZE_COLUMN_MAP = {
  small: 1,
  medium: 2,
};
