/**
 * React component for rendering medium list widget.
 *
 * @typedef {Object} Event
 * @property {string} topic
 * @property {string} eventUrl
 * @property {string} meetingUrl
 * @property {string} startTime
 * @property {string} endTime
 *
 * @typedef {Object} data
 * @property {Object} Event
 * @property {String} title
 * @property {String} noneText
 *
 * @typedef {Object} File
 * @property {String} id
 * @property {String} name
 * @property {String} mimeType
 * @property {String} url
 * @property {String} iconSrc
 */

import { Box, Typography, Button, Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

import WidgetIconPile from "./WidgetIconPile";

import { formatReadableTimeRange, isToday } from "@cosy/utils/datetime";
import { DATE_TOKEN_FORMATTERS } from "@cosy/utils/format_markdown";

export default function WidgetUpNextMedium({ data }) {
  const isLoading = data === undefined;

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      padding={2}
      minWidth={0}
    >
      <Box mb={1}>
        <Typography variant="h6" color="textSecondary" gutterBottom noWrap>
          {!isLoading ? data.title : <Skeleton width={80} />}
        </Typography>
      </Box>
      {!isLoading && !data.event ? (
        <Typography variant="body2" color="textSecondary">
          {data.noneText || "No event here"}
        </Typography>
      ) : (
        <EventContent isLoading={isLoading} event={data?.event} />
      )}
    </Box>
  );
}

function EventContent({ isLoading, event }) {
  return (
    <Box flex="1" display="flex" flexDirection="column">
      <Typography variant="h2" noWrap color="textPrimary">
        {!isLoading ? (
          event.eventUrl ? (
            <Link href={event.eventUrl} target="_blank">
              {event.topic}
            </Link>
          ) : (
            event.topic
          )
        ) : (
          <Skeleton width={40} />
        )}
      </Typography>
      {!isLoading ? (
        event.startTime &&
        event.endTime && (
          <Typography
            variant="body2"
            color="textSecondary"
            noWrap
            paragraph
            inline
          >
            {_getInfo(event)}
          </Typography>
        )
      ) : (
        <Skeleton width={60} />
      )}
      {!isLoading && (
        <Box display="flex" alignItems="center">
          {event.meetingUrl && (
            <Button
              variant="contained"
              color="primary"
              href={event.meetingUrl}
              target="_blank"
            >
              Join meeting
            </Button>
          )}
          {event.files && (
            <Box ml="auto">
              <WidgetIconPile items={event.files.map(_formatFileAsIconPile)} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

/**
 * Returns the info text of Up Next meeting for use in a widget.
 *
 * @private
 */
function _getInfo(event) {
  const startTime = new Date(event.startTime);
  const endTime = new Date(event.endTime);

  if (isToday(startTime)) {
    return formatReadableTimeRange(startTime, endTime);
  }

  return (
    <>
      {DATE_TOKEN_FORMATTERS.date_short(startTime)}{" "}
      {formatReadableTimeRange(startTime, endTime)}
    </>
  );
}

/**
 * @private
 */
function _formatFileAsIconPile({ id, name, mimeType, url, iconSrc }) {
  return {
    id,
    linkUrl: url,
    linkTitle: name,
    iconSrc,
    iconAlt: `${mimeType} file`,
  };
}
