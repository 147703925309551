import { useState, useEffect } from "react";

import {
  useSourceFacetValues,
  useSubTypeFacetValues,
} from "@cosy/hooks/use_algolia_facets";
import useSearchState from "@cosy/hooks/use_search_state";
import { FACETS } from "@cosy/lib/constants/results";

const INITIAL_STATE = {
  [FACETS.SOURCE]: [],
  [FACETS.SUB_TYPE]: [],
};

/**
 * Hook for components to get filters related data.
 *
 * @returns {object}
 */
export default function useSearchFilters() {
  const { setSearchParams, isQueryReset } = useSearchState();
  const { facetHits: subTypeFacetHits } = useSubTypeFacetValues();
  const { facetHits: sourceFacetHits } = useSourceFacetValues();
  const [selectedFacets, setSelectedFacets] = useState(INITIAL_STATE);

  _usePruneFacetValues(
    FACETS.SUB_TYPE,
    subTypeFacetHits,
    selectedFacets,
    setSelectedFacets
  );

  _usePruneFacetValues(
    FACETS.SOURCE,
    sourceFacetHits,
    selectedFacets,
    setSelectedFacets
  );

  // Whenever selected facets state changes, update searchParams
  useEffect(() => {
    setSearchParams((searchParams) => ({
      ...searchParams,
      page: 0,
      facetFilters: [
        selectedFacets[FACETS.SOURCE].map((v) => `${FACETS.SOURCE}:${v}`),
        selectedFacets[FACETS.SUB_TYPE].map((v) => `${FACETS.SUB_TYPE}:${v}`),
      ].filter((ff) => ff.length > 0),
    }));
  }, [selectedFacets, setSearchParams]);

  useEffect(() => {
    if (isQueryReset) {
      setSelectedFacets(INITIAL_STATE);
    }
  }, [isQueryReset, setSelectedFacets]);

  const addFilter = (facetName, value) => {
    setSelectedFacets((currentState) => {
      const currentValues = currentState[facetName];
      if (currentValues.includes(value)) {
        return currentState;
      }
      return { ...currentState, [facetName]: [value, ...currentValues] };
    });
  };

  const removeFilter = (facetName, value) => {
    setSelectedFacets((currentState) => {
      const currentValues = currentState[facetName];

      if (!currentValues.includes(value)) {
        return currentState;
      }

      currentValues.splice(currentValues.indexOf(value), 1);

      return { ...currentState, [facetName]: [...currentValues] };
    });
  };

  const replaceFilters = (facetName, values) => {
    setSelectedFacets((currentState) => {
      return { ...currentState, [facetName]: values };
    });
  };

  return {
    addFilter,
    removeFilter,
    replaceFilters,
    selectedFacets,
    subTypes: subTypeFacetHits,
    sources: sourceFacetHits,
  };
}

/**
 * Removes selected filters which have become unavailable in one facet, due to
 * changes in another. For example, if the user filters to "Docs" and "Issues"
 * for subType, but then filters to "Google Drive" as the source, we
 * automatically remove the "Issues" filter.
 *
 * @param {string} facetName
 * @param {Array} facetHits
 * @param {Array} selectedFacets
 * @param {Function} setSelectedFacets
 */
function _usePruneFacetValues(
  facetName,
  facetHits,
  selectedFacets,
  setSelectedFacets
) {
  useEffect(() => {
    const selectedFacetValues = selectedFacets[facetName];
    const possibleSelectedState = selectedFacetValues.filter((value) =>
      facetHits.map((hit) => hit.value).includes(value)
    );
    if (possibleSelectedState.length !== selectedFacetValues.length) {
      setSelectedFacets((currentState) => ({
        ...currentState,
        [facetName]: possibleSelectedState,
      }));
    }
  }, [facetName, facetHits, selectedFacets, setSelectedFacets]);
}
