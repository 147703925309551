import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useRouter } from "next/router";
import { useState } from "react";

import ActionConfirm from "./ActionConfirm";

import usePostInteraction from "@cosy/hooks/use_post_interaction";

function useActionResponse(hit) {
  const [confirmOptions, setConfirmOptions] = useState(null);
  const router = useRouter();
  const postInteraction = usePostInteraction();

  async function _respondToAction(action) {
    if (action.url) {
      router.push(action.url);
      return;
    }

    postInteraction(hit, action);
  }

  function clickAction(action) {
    if (action.confirm) {
      setConfirmOptions({
        ...action.confirm,
        onAccept: _respondToAction.bind(this, action),
      });
    } else {
      _respondToAction(action);
    }
  }

  return { clickAction, confirmOptions };
}

export default function ActionButtons({ hit }) {
  const { clickAction, confirmOptions } = useActionResponse(hit);

  if (!hit.actions) {
    return null;
  }

  return (
    <Box mt={2}>
      <Grid container spacing={1}>
        {hit.actions.map((action, i) => {
          return (
            <Grid item key={i}>
              <Button color="primary" onClick={() => clickAction(action)}>
                {action.text.text}
              </Button>
            </Grid>
          );
        })}
      </Grid>
      <ActionConfirm options={confirmOptions} />
    </Box>
  );
}
