import {
  Avatar,
  Box,
  Chip,
  FormControl,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  BookmarkBorderOutlined,
  BugReportOutlined,
  BusinessOutlined,
  CalendarTodayOutlined,
  DescriptionOutlined,
  FolderOutlined,
  ImageOutlined,
  InsertDriveFileOutlined,
  MergeTypeRounded,
  PersonOutlineOutlined,
  WebAssetOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import { rgba } from "polished";
import useSWR from "swr";

import useSearchFilters from "@cosy/hooks/use_search_filters";
import {
  CUSTOM_SUB_TYPES,
  SUB_TYPES,
  SUB_TYPES_PLURALIZED,
  FACETS,
} from "@cosy/lib/constants/results";

const FILTER_ICONS = {
  [SUB_TYPES.DOCUMENT]: DescriptionOutlined,
  [SUB_TYPES.PAGE]: InsertDriveFileOutlined,
  [SUB_TYPES.COMPANY]: BusinessOutlined,
  [SUB_TYPES.DIRECTORY]: FolderOutlined,
  [SUB_TYPES.EVENT]: CalendarTodayOutlined,
  [SUB_TYPES.IMAGE]: ImageOutlined,
  [SUB_TYPES.ISSUE]: BugReportOutlined,
  [SUB_TYPES.PERSON]: PersonOutlineOutlined,
  [SUB_TYPES.PULL_REQUEST]: MergeTypeRounded,
  [SUB_TYPES.PRESENTATION]: WebAssetOutlined,
  [CUSTOM_SUB_TYPES.BOOKMARK]: BookmarkBorderOutlined,
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
  selectedChip: {
    backgroundColor: rgba(theme.palette.text.secondary, 0.1),
  },
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    marginTop: 0,
    fontSize: theme.typography.pxToRem(13),
    height: theme.spacing(4),
  },
  selectItem: {
    fontSize: theme.typography.pxToRem(13),
  },
  avatar: {
    width: 24,
    height: 24,
    "$chip &": {
      width: 20,
      height: 20,
      marginLeft: 8,
      marginRight: -4,
    },
  },
}));

export default function SearchFilters() {
  const {
    sources,
    subTypes,
    addFilter,
    removeFilter,
    replaceFilters,
    selectedFacets,
  } = useSearchFilters();
  const { data: apps } = useSWR("/api/apps");

  if (subTypes.length === 0 && sources.length === 0) {
    // Empty div with MUI Chip px height to avoid jumping search results
    return <div style={{ height: 32 }} data-testid="empty-filters" />;
  }

  const selectedSubTypes = selectedFacets[FACETS.SUB_TYPE];
  const availableSubTypes = subTypes.filter(
    ({ value }) => !selectedSubTypes.includes(value)
  );

  const availableSubTypesLimit = Math.max(3 - selectedSubTypes.length, 0);
  const mainAvailableSubTypes = availableSubTypes.slice(
    0,
    availableSubTypesLimit
  );
  const otherAvailableSubTypes = availableSubTypes.slice(
    availableSubTypesLimit
  );

  const selectedSources = selectedFacets[FACETS.SOURCE];
  const availableSources = sources.filter(
    ({ value }) => !selectedSources.includes(value)
  );

  return (
    <Box display="flex" alignItems="center">
      {(selectedSubTypes.length > 0 || selectedSources.length > 0) && (
        <div>
          {selectedSubTypes.map((value) => (
            <FilterChip
              key={value}
              label={SUB_TYPES_PLURALIZED[value] || value}
              value={value}
              onClick={() => removeFilter(FACETS.SUB_TYPE, value)}
              avatar={_getChipIconForSubType(value)}
              selected
            />
          ))}
          {selectedSources.map((value) => (
            <FilterChip
              key={value}
              label={value}
              value={value}
              onClick={() => removeFilter(FACETS.SOURCE, value)}
              avatar={<SelectedFilterChipAvatar value={value} />}
              selected
            />
          ))}
        </div>
      )}
      {subTypes.length > 1 && availableSubTypes.length > 0 && (
        <>
          <div>
            {mainAvailableSubTypes.map((subType) => (
              <FilterChip
                key={subType.value}
                label={SUB_TYPES_PLURALIZED[subType.value] || subType.value}
                value={subType.value}
                onClick={() => addFilter(FACETS.SUB_TYPE, subType.value)}
                avatar={_getChipIconForSubType(subType.value)}
              />
            ))}
          </div>
          {otherAvailableSubTypes.length > 0 && (
            <FilterDropdown
              label="Other"
              options={otherAvailableSubTypes.map((subType) => ({
                label: SUB_TYPES_PLURALIZED[subType.value],
                value: subType.value,
              }))}
              selectedValues={selectedSubTypes}
              setFilters={(values) => replaceFilters(FACETS.SUB_TYPE, values)}
            />
          )}
        </>
      )}
      {sources.length > 1 && availableSources.length > 0 && (
        <FilterDropdown
          label="Apps"
          options={availableSources.map((source) => {
            const currentApp = apps?.find((app) => app.name === source.value);
            return {
              ...source,
              iconSrc: currentApp?.defaultResultIcon,
            };
          })}
          selectedValues={selectedSources}
          setFilters={(values) => replaceFilters(FACETS.SOURCE, values)}
        />
      )}
    </Box>
  );
}

function FilterChip({ label, value, onClick, onDelete, avatar, selected }) {
  const classes = useStyles();
  return (
    <Chip
      className={clsx(classes.chip, selected && classes.selectedChip)}
      value={value}
      clickable
      variant="outlined"
      label={label}
      avatar={avatar}
      onClick={onClick}
      onDelete={onDelete}
    />
  );
}

function SelectedFilterChipAvatar({ value }) {
  const classes = useStyles();
  const { data: apps } = useSWR("/api/apps");
  const selectedApp = apps?.find((app) => app.name === value);
  return (
    <Avatar src={selectedApp?.defaultResultIcon} className={classes.avatar}>
      {value.slice(0, 1)}
    </Avatar>
  );
}

function FilterDropdown({ label, options, selectedValues, setFilters }) {
  const classes = useStyles();

  // The selected values that are a part of this dropdown (some may be
  // represented by chips elsewhere)
  const includedSelectedValues = selectedValues.filter((value) =>
    options.some((o) => o.value === value)
  );

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={classes.formControl}
    >
      <Select
        multiple
        value={selectedValues}
        onChange={(e) => setFilters(e.target.value)}
        renderValue={() =>
          includedSelectedValues.length > 0
            ? includedSelectedValues.join(", ")
            : label
        }
        displayEmpty
        className={classes.select}
        inputProps={{ "aria-label": label }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.selectItem}
          >
            {option.iconSrc && (
              <ListItemIcon>
                <Avatar src={option.iconSrc} className={classes.avatar}>
                  {option.value?.slice(0, 1)}
                </Avatar>
              </ListItemIcon>
            )}
            <ListItemText primary={option.label || option.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function _getChipIconForSubType(subType) {
  const SubTypeIcon = FILTER_ICONS[subType];
  if (!SubTypeIcon) {
    return;
  }

  return <SubTypeIcon style={{ width: 18, height: 18 }} />;
}
