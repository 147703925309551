/**
 * Tested in Widget.test.jsx
 */
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import RadialProgress from "./RadialProgress";

export default function WidgetNumberSmall({ data }) {
  const hasData = data !== undefined;

  return (
    <Box display="flex" flexDirection="column" padding={2}>
      <Box flex="1">
        {!data?.progress && data?.iconSrc && (
          <img src={data.iconSrc} alt="" width="24" height="24" />
        )}
        {data?.progress && <RadialProgress {...data.progress} />}
      </Box>
      <Typography
        variant="h1"
        color="textPrimary"
        style={{ fontSize: "3rem", fontWeight: "400" }}
      >
        {hasData ? data.count : <Skeleton width={40} />}
      </Typography>
      <Typography variant="body2" color="textPrimary" noWrap>
        {hasData ? data.line1 : <Skeleton width={80} />}
      </Typography>
      <Typography variant="body2" color="textSecondary" noWrap>
        {hasData ? data.line2 : <Skeleton width={60} />}
      </Typography>
    </Box>
  );
}
