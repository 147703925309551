import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddRounded } from "@material-ui/icons";
import dynamic from "next/dynamic";
import { useState, Fragment } from "react";

import Clock from "@cosy/components/Clock";
import InternalLink from "@cosy/components/InternalLink";
import OnboardingCards from "@cosy/components/OnboardingCards";
import PreviewPane from "@cosy/components/PreviewPane";
import SearchFilters from "@cosy/components/SearchFilters";
import SearchResults from "@cosy/components/SearchResults";
import WidgetDrawer from "@cosy/components/WidgetDrawer";
import useAlgoliaIndex from "@cosy/hooks/use_algolia_index";
import useDebouncedLivesearch from "@cosy/hooks/use_debounced_livesearch";
import useMe from "@cosy/hooks/use_me";
import useMembership from "@cosy/hooks/use_membership";
import useSearchState from "@cosy/hooks/use_search_state";
import useWidgetSizeStyles from "@cosy/hooks/use_widget_size_styles";
import useWorkspace from "@cosy/hooks/use_workspace";
import { STATUSES } from "@cosy/lib/constants/connections";

const DynamicWidgetsGrid = dynamic(
  () => import("@cosy/components/DynamicWidgetsGrid"),
  {
    ssr: false,
  }
);

const useStyles = makeStyles(() => ({
  addWidgetButton: {
    opacity: 0,
    transition: "all 100ms ease-in",
    transform: "translateY(0)",
    margin: "12px 0 36px",
  },
  widgetGridContainer: {
    "&:hover $addWidgetButton": {
      opacity: 1,
      transform: "translateY(10px)",
    },
  },
}));

export default function WorkspaceView() {
  const { query, isBrowseMode } = useSearchState();
  const { workspace } = useWorkspace();
  const { me } = useMe();
  const membership = useMembership(me);

  const algoliaIndex = useAlgoliaIndex();
  useDebouncedLivesearch();

  if (!workspace) {
    return null;
  }

  const showSearchUI = query || isBrowseMode;

  return (
    <div>
      {!!algoliaIndex && (
        <Box mb={6}>
          <SearchFilters />
        </Box>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} sm={7}>
          {showSearchUI ? (
            <SearchResults />
          ) : (
            <Fragment>
              <Clock />
              <StatusSection />
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={5}>
          {showSearchUI ? (
            <Paper>
              <PreviewPane />
            </Paper>
          ) : (
            <WidgetsArea widgets={membership?.widgets} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function WidgetsArea({ widgets }) {
  const [isWidgetDrawerOpen, setIsWidgetDrawerOpen] = useState(false);
  const classes = useStyles();
  const widgetClasses = useWidgetSizeStyles();

  function onAddWidget() {
    setIsWidgetDrawerOpen(!isWidgetDrawerOpen);
  }

  function onClose() {
    if (isWidgetDrawerOpen) {
      setIsWidgetDrawerOpen(false);
    }
  }

  return (
    <Box className={widgetClasses.wrapper}>
      <OnboardingCards onAddWidget={onAddWidget} />
      <div className={classes.widgetGridContainer}>
        {widgets?.length > 0 && (
          <>
            <DynamicWidgetsGrid widgets={widgets} />
            <IconButton
              className={classes.addWidgetButton}
              onClick={onAddWidget}
              size="small"
            >
              <AddRounded fontSize="small" titleAccess="Add" />
            </IconButton>
          </>
        )}
      </div>
      <WidgetDrawer onClose={onClose} open={isWidgetDrawerOpen} />
    </Box>
  );
}

function StatusSection() {
  const { me } = useMe();
  const { workspace } = useWorkspace();
  const connections = me?.connections?.filter(
    (connection) => connection.status.state === STATUSES.ERROR.id
  );

  const reconnectLink = (
    <InternalLink
      href={`/${workspace.slug}/settings/my-account`}
      linkComponent={Button}
      linkProps={{ variant: "outlined", size: "small" }}
    >
      Fix {connections?.length === 1 ? "it" : "them"}
    </InternalLink>
  );

  if (connections?.length === 1 && connections[0].providerName) {
    return (
      <Fragment>
        <Typography color="error" gutterBottom>
          Oh no, we ran into an issue with {connections[0].providerName}.
        </Typography>
        {reconnectLink}
      </Fragment>
    );
  } else if (connections?.length > 1) {
    return (
      <Fragment>
        <Typography color="error" gutterBottom>
          Oh no, we ran into issues with some of your connections.
        </Typography>
        {reconnectLink}
      </Fragment>
    );
  }

  return null;
}
