/**
 * React component for rendering medium list widget.
 *
 * @typedef {Object} ListItem
 * @property {String} icon
 * @property {String} href
 * @property {String} primaryText
 * @property {String} secondaryText
 *
 * @typedef {Object} ListData
 * @property {String} icon
 * @property {ListItem[]} items
 * @property {String} noneText
 */

import {
  Box,
  ListItemText,
  List,
  ListItem,
  ListItemAvatar,
  ListSubheader,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import React from "react";

import { HitIconImg } from "./HitIcon";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: "28px",
  },
  subHeader: {
    padding: theme.spacing(1, 2),
  },
}));

export default function WidgetMediumList({ data }) {
  const hasData = data !== undefined;
  const classes = useStyles();

  return (
    <Box alignSelf="flex-start" overflow="hidden" width="100%">
      <List
        dense
        disablePadding
        subheader={
          <ListSubheader className={classes.subHeader}>
            {hasData ? (
              <img
                style={{ display: "inline-block" }}
                src={data.icon}
                alt=""
                width={24}
                height={24}
              />
            ) : (
              <Skeleton variant="circle" width={24} height={24} />
            )}
          </ListSubheader>
        }
      >
        {hasData ? (
          <React.Fragment>
            {data.items.map((item, index) => (
              <ListItem
                key={index}
                button={!!item.href}
                component={item?.href ? ButtonBase : "div"}
                href={item?.href}
                target={item?.href && "_blank"}
                alignItems="flex-start"
                divider={index !== data.items.length - 1}
              >
                <ListItemAvatar className={classes.icon}>
                  <HitIconImg src={item.icon} size={18} />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                  secondaryTypographyProps={{
                    noWrap: true,
                  }}
                  primary={item.primaryText}
                  secondary={item.secondaryText}
                />
              </ListItem>
            ))}
            {data.items.length === 0 && data.noneText && (
              <ListItem>
                <ListItemText>
                  <Typography noWrap variant="body2" color="textSecondary">
                    {data.noneText}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ListItem dense alignItems="flex-start" divider>
              <Skeleton width="100%" height={40} />
            </ListItem>
            <ListItem dense alignItems="flex-start">
              <Skeleton width="100%" height={40} />
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </Box>
  );
}
