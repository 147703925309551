import { IconButton, Menu } from "@material-ui/core";
import {
  MoreVert as MoreVertIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@material-ui/icons";
import { useState } from "react";

import InternalMenuItem from "@cosy/components/InternalMenuItem";
import useMe from "@cosy/hooks/use_me";
import useMembership from "@cosy/hooks/use_membership";
import useSlug from "@cosy/hooks/use_slug";
import { post, del } from "@cosy/utils/api";
import { canDeleteResult } from "@cosy/utils/users";

export default function HitMenu({ hit }) {
  const slug = useSlug();
  const { me, mutate: mutateMe } = useMe();
  const membership = useMembership(me);
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const removeResult = async (objectID) => {
    await del(`/workspaces/${slug}/results/${encodeURIComponent(objectID)}`);
    mutateMe();
    closeMenu();
  };

  const addShortcut = async ({ title, url, icon, objectID }) => {
    await post(`/workspaces/${slug}/widgets/shortcut`, {
      title,
      url,
      iconSrc: icon,
      algoliaObjectID: objectID,
    });
    closeMenu();
  };

  const { visibility } = hit;

  if (!membership && !slug) {
    // If none are present then don't show the menu at all
    return null;
  }

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={openMenu}
      >
        <MoreVertIcon titleAccess="More actions" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {slug && (
          <InternalMenuItem
            iconComponent={AddCircleOutlineIcon}
            onClick={() => addShortcut(hit)}
          >
            Add to home screen
          </InternalMenuItem>
        )}
        {membership && canDeleteResult(membership, visibility) && (
          <InternalMenuItem
            iconComponent={RemoveCircleOutlineIcon}
            onClick={() => removeResult(hit.objectID)}
          >
            Remove
          </InternalMenuItem>
        )}
      </Menu>
    </>
  );
}
