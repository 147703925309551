/**
 * Data object sent for the WidgetAgendaMedium widget.
 *
 * @typedef {Object} WidgetAgendaData
 *
 * @property {WidgetAgendaEventData[]} events
 * @property {string} noneText - Text to show if there are no events.
 */

/**
 * Event data for WidgetAgendaData
 *
 * @typedef {Object} WidgetAgendaEventData
 *
 * @property {string} startTime - ISO-formatted datetime.
 * @property {string} endTime - ISO-formatted datetime.
 * @property {string} topic - Event title.
 * @property {?string} href - URL for the event.
 */

import { Box, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

import { formatReadableTimeRange } from "@cosy/utils/datetime";

const useStyles = makeStyles((theme) => ({
  eventDot: {
    borderRadius: 10,
    width: 10,
    height: 10,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    flexShrink: 0,

    "$eventRow.isPast &": {
      backgroundColor: "transparent",
      borderColor: theme.palette.text.secondary,
    },
  },

  eventRow: {
    marginBottom: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",

    "& a": {
      color: theme.palette.text.primary,
    },

    "&.isCurrent": {
      "& $eventRowText": {
        fontWeight: 500,
      },
    },

    "&.isPast": {
      opacity: 0.5,
    },
  },
  eventRowText: {},
}));

export default function WidgetAgendaMedium({ data }) {
  const isLoading = data === undefined;
  const date = new Date();

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={2}
      alignItems="stretch"
      minWidth={0}
    >
      <Box flex="1" pt={1}>
        <Typography variant="h3">
          {date.toLocaleDateString("en-US", { month: "short", day: "numeric" })}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date.toLocaleDateString("en-US", { weekday: "short" })}
        </Typography>
      </Box>
      {!isLoading ? (
        <EventsList noneText={data.noneText} events={data?.events} />
      ) : (
        <Skeleton width={120} />
      )}
    </Box>
  );
}

function EventsList({ events, noneText }) {
  const classes = useStyles();

  return events.length ? (
    <div>
      {events.map((event, i) => {
        const start = new Date(event.startTime);
        const end = new Date(event.endTime);
        const isPast = end.getTime() <= Date.now();
        const isCurrent =
          start.getTime() <= Date.now() && end.getTime() > Date.now();

        return (
          <div
            key={i}
            className={clsx(classes.eventRow, { isPast, isCurrent })}
          >
            <div className={classes.eventDot}></div>
            <Box mr={0.5}>
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
                noWrap
              >
                {formatReadableTimeRange(start, end)}
              </Typography>
            </Box>
            <Typography
              component="span"
              variant="body2"
              color={isPast ? "textSecondary" : "textPrimary"}
              noWrap
              className={classes.eventRowText}
            >
              {event.href ? (
                <Link href={event.href} target="_blank">
                  {event.topic}
                </Link>
              ) : (
                event.topic
              )}
            </Typography>
          </div>
        );
      })}
    </div>
  ) : (
    <Box flex="4">
      <Typography variant="body2" color="textSecondary">
        {noneText}
      </Typography>
    </Box>
  );
}
