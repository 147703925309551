import { useState, useEffect } from "react";

/**
 * Hook to get Chrome extension information.
 *
 * @returns {string} Version.
 */
export default function useChromeExtension() {
  const [version, setVersion] = useState();

  useEffect(() => {
    const runtime = window.chrome?.runtime;
    if (runtime) {
      runtime.sendMessage(
        process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID,
        { message: "version" },
        (reply) => setVersion(reply?.version ?? false)
      );
    } else {
      setVersion(false);
    }
  }, []);

  return {
    canBeInstalled: !!window.chrome && !window.chrome.extension,
    isLoading: version == null,
    isInstalled: !!version,
  };
}
